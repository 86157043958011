<template>
  <div style="margin: 20px 0">
    <el-row>
      <el-col :span="16">
        <el-button
          size="small"
          type="primary"
          @click="showFilter = !showFilter"
        >
          Filter
        </el-button>
        <el-button
          size="small"
          type="success"
          @click="exportOrders"
          :disabled="exportLoading === true || listData.length < 1"
        >
          <i class="el-icon-loading" v-if="exportLoading === true"></i> Export File
        </el-button>
      </el-col>
    </el-row>
  </div>
  <div style="margin: 20px 0" v-if="showFilter">
    <el-form>
      <el-card shadow="hover">
        <el-row :gutter="30">
          <el-col :span="5">
            <el-form-item label="Target Order ID">
              <el-input v-model="orderId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="Hub Order ID">
              <el-input v-model="hubId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="Status">
                <el-select
              style="width: -webkit-fill-available"
              filterable
              v-model="condition.completed"
              placeholder="Search"
            >
              <el-option
                v-for="item in listStatus"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="6">
            <el-button size="small" type="success" @click="getSearchData()">
              Search
            </el-button>
            <el-button size="small" type="primary" @click="handleClear()">
              Clear
            </el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
  </div>
    <el-table :data="listData" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="44"> </el-table-column>
      <el-table-column label="Target Order ID">
        <template #default="scope">
          <template v-for="orderId in targetOrderIds" :key="orderId">
            <template v-if="scope.row[orderId] != undefined && scope.row[orderId] != ''">{{ scope.row[orderId] }}</template>
          </template>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Hub Order ID">
        <template #default="scope">
          {{ scope.row[hubOrderId] }}
        </template>
    </el-table-column> -->
    <el-table-column label="Hub Order ID"> 
      <template #default="scope">
        {{ scope.row[hubOrderId] }}
      </template>
    </el-table-column>
    <el-table-column  label="Target Name">
      <template #default="scope">
        <template v-if="scope.row.connectionTarget"> {{ scope.row.connectionTarget.name }}</template>
      </template>
    </el-table-column>
    <!-- <el-table-column prop="dateCreated" label="Date Created">
      <template #default="scope">
        {{ new Date(scope.row.dateCreated).toLocaleString() }}
      </template>
    </el-table-column> -->
    <!-- <el-table-column prop="dateUpdated" label="Last Updated">
      <template #default="scope">
        {{ new Date(scope.row.dateUpdated).toLocaleString() }}
      </template>
    </el-table-column> -->
    <el-table-column label="Status" prop="completed">
      <template #default="scope">
        <div v-if="scope.row.completed" size="medium">
            <el-tooltip
                class="item"
                effect="light"
                content="Completed"
                placement="right"
              >
                <el-button type="success" icon="el-icon-check" circle   size="small"></el-button>
            </el-tooltip>
        </div>
        <div v-else>
          <el-tooltip
          class="item"
          effect="light"
          content="Failed"
          placement="right"
        >
          <el-button v-if="scope.row.numberOfRetry < 5" type="warning" icon="el-icon-close" circle   size="small"></el-button>
          <el-button v-else type="danger" icon="el-icon-close" circle   size="small"></el-button>
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
    <!-- <el-table-column label="Number Of Retry" prop="history">
      <template #default="scope">
          <div >
              <p>{{scope.row.numberOfRetry}}</p>
          </div>
        </template>
    </el-table-column> -->
    <el-table-column label="Timestamp" prop="timeStamp"></el-table-column>
    <el-table-column fixed="right" label="Inspect" width="120">
      <template #default="scope">
        <el-tooltip
          class="item"
          effect="light"
          content="Detail"
          placement="right"
        >
          <el-button
          size="small"
            @click="viewDetail(scope.row)"
          icon="el-icon-document"
          type="primary"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="Order Detail" v-model="dialogOrderDetail">
    <order-detail-modal :item="dataItem" />
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogOrderDetail = false">Cancel</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import OrderDetailModal from './OrderDetailModal.vue'
import { ElMessageBox, ElMessage } from 'element-plus'

@Options({
  components: {
    OrderDetailModal,
  },
  props: {
    listData: [],
  },
})
export default class OrderTable extends Vue {
  name = "Order Table";
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    "account": localStorage.getItem("account"),
  };
  dataItem = {};
  dialogOrderDetail = false;
  viewDetail(row) {
    this.dataItem = row;
    this.dialogOrderDetail = true;
  }
  connectData = {};
  hubOrderId = ''
  targetOrderIds = []
  target = ''
  showFilter = false;
  orderId = '';
  hubId = '';
  condition = {
    completed: undefined,
  } ;
  listStatus = [
    {label:"All",value:undefined},
    {label:"Completed",value:true},
    {label:"Failed",value:false},
  ]
  multipleSelection = []
  exportLoading = false

  created () {
    this.getListConnect()
  }
  async getListConnect() {
    let res = await axios.get("user/connect/list", {
      headers: this.headers,
    });
    if (res.data) {
      this.connectData = res.data;
      for (let connect of this.connectData) {
        if (connect.connectFrom) {
          this.hubOrderId = connect.type + "OrderId"
        } else {
           if (this.targetOrderIds.indexOf(connect.type + "OrderId") == -1) {
                        this.targetOrderIds.push(connect.type + "OrderId")
                    }
        }
      }
    }
  }
  async getSearchData() {
    this.condition['$or'] = undefined;
    this.condition['$and'] = undefined;
    if (this.orderId.length > 0 && this.hubId.length > 0) {
      this.condition['$and'] = [
        {
          '$or': []
        },
        {
          [this.hubOrderId]: { $regex: this.hubId, $options: 'i' }
        }
      ]
      for (let id of this.targetOrderIds) {
          this.condition['$and'][0]['$or'].push({ [id]: { $regex: this.orderId, $options: 'i' } })
        }
    } else {
      this.condition['$or'] = []
      if (this.orderId.length > 0) {
        for (let id of this.targetOrderIds) {
          this.condition['$or'].push({ [id]: { $regex: this.orderId, $options: 'i' } })
        }
      }
      if (this.hubId.length > 0) {
        this.condition['$or'].push({ [this.hubOrderId]: { $regex: this.hubId, $options: 'i' } })
      }
      if (this.orderId.length == 0 && this.hubId.length == 0) {
        delete this.condition['$or']
      }
    }
    let data = {
      condition: this.condition,
    };
    this.$emit("search", data);
  }
  handleClear() {
    this.condition.completed = undefined;
    this.condition['$or'] = undefined;
    this.condition['$and'] = undefined;
    this.orderId = ''
    this.hubId = ''
    this.getSearchData()
  }
  handleSelectionChange(val) {
    this.multipleSelection = val
  }
  exportOrders() {
    ElMessageBox.confirm(
      'Do you confirm to export all selected. Continue?',
      'Warning',
      {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'success',
      }
    )
      .then(async () => {
        this.exportLoading = true
        this.headers.connectionTo = this.$store.state['targetConnection']
        let multipleSelectionCondition = { '$or': [] }
        if (this.multipleSelection.length > 0) {
          this.condition['$or'] = []
          for (let item of this.multipleSelection) {
            let itemTargetId = ''
            for (let type of this.targetOrderIds) {
              if (item[type] !== null) {
                itemTargetId = type
                break
              }
            }
            if (itemTargetId != '') {
              multipleSelectionCondition['$or'].push({
                [itemTargetId]: { $regex: item[itemTargetId], $options: 'i' }
              })
            }
          }
        }
        if (this.condition.completed === true || this.condition.completed === false) multipleSelectionCondition.completed = this.condition.completed
        if (this.condition['$and'] && this.condition['$and'].length) multipleSelectionCondition['$and'] = this.condition['$and']
        if (this.condition['$or'] && this.condition['$or'].length) {
          multipleSelectionCondition['$or'].push(...this.condition['$or'])
        }
        let attributeList = Object.keys(this.listData[0])
        let uniqueAttributes = new Set([this,this.hubOrderId, ...this.targetOrderIds, ...attributeList])
        delete uniqueAttributes.__v
        let selectAttributes = [...uniqueAttributes]
        selectAttributes.shift()
        selectAttributes = selectAttributes.join(' ')
        let res = await axios
          .post('user/order/export', {
            headers: this.headers,
            condition: multipleSelectionCondition,
            select: selectAttributes
          })
          .catch((err) => console.log('[ERROR]', err))
        if (res) {
          const linkFileExport = (await res.data.linkFileExport) || ''
          if (
            linkFileExport != undefined ||
            linkFileExport != null ||
            linkFileExport != ''
          ) {
            // window.location = linkFileExport;
            ElMessageBox.alert(
              'The export file will be send to the email addresses in Notifications section. Please check your mailbox after few minutes.',
              'Notification',
              {
                confirmButtonText: 'OK',
                type: 'success',
                center: true
              }
            )
            this.exportLoading = false
          } else {
            ElMessageBox.alert('Error!!', 'Notification', {
              confirmButtonText: 'Close',
              type: 'warning',
              center: true
            })
          }
        }
      })
      .catch(() => console.log('null'))
  }
}
</script>
<style scoped>
/* Styles for larger screens */
.desktop-content {
  display: block;
}

/* Styles for mobile screens */
.mobile-content {
  display: none;
}

@media screen and (max-width: 600px) {
  .desktop-content {
    display: none;
  }

  .mobile-content {
    display: block;
  }
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_table = _resolveComponent("order-table")
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_order_table, {
      listData: _ctx.listOrder,
      onSearch: _ctx.handleSearchOrder
    }, null, 8, ["listData", "onSearch"]),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_el_pagination, {
        background: "",
        "page-sizes": [20, 50, 100],
        "page-size": _ctx.perPage,
        "current-page": _ctx.currentPage,
        layout: "total, prev, pager, next, sizes, jumper",
        total: _ctx.totaRows,
        onCurrentChange: _ctx.handleCurrentChange,
        onSizeChange: _ctx.handleSizeChange,
        onPrevClick: ($event: any) => (1)
      }, null, 8, ["page-size", "current-page", "total", "onCurrentChange", "onSizeChange"])
    ])
  ], 64))
}